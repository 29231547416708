import _ from 'lodash';
import { AccountInfo } from '@azure/msal-browser';
import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { cityPairArrayToString } from '../../utils/cityPairs.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAccount } from '@azure/msal-react';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

import { CityPairArray, RuleData } from '../../types';
import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';

type RulesDataAndState = {
    active: {
        data: RuleData[];
        loading: boolean;
        reload: boolean;
    };
    inactive: {
        data: RuleData[];
        loading: boolean;
        reload: boolean;
    };
};

type SetLoadingStateAction = {
    type: 'set_loading_state';
    payload: { isActive: boolean; newLoadingState: boolean };
};

type SetReloadStateAction = {
    type: 'set_reload_state';
    payload: { isActive: boolean; newReloadState: boolean };
};

type SetRulesDataAction = {
    type: 'set_rules_data';
    payload: { isActive: boolean; newRulesData: RuleData[] };
};

type UpdateRuleActiveStatusAction = {
    type: 'update_rule_active_status';
    payload: { ruleId: number; newActiveStatus: boolean; account: AccountInfo | null };
};

function rulesDataAndStateReducer(
    state: RulesDataAndState,
    action: SetLoadingStateAction | SetReloadStateAction | SetRulesDataAction | UpdateRuleActiveStatusAction,
): RulesDataAndState {
    switch (action.type) {
        case 'set_loading_state': {
            const activeKey = action.payload.isActive ? 'active' : 'inactive';
            return {
                ...state,
                [activeKey]: {
                    ...state[activeKey],
                    loading: action.payload.newLoadingState,
                },
            };
        }
        case 'set_reload_state': {
            const activeKey = action.payload.isActive ? 'active' : 'inactive';
            return {
                ...state,
                [activeKey]: {
                    ...state[activeKey],
                    reload: action.payload.newReloadState,
                },
            };
        }
        case 'set_rules_data': {
            const activeKey = action.payload.isActive ? 'active' : 'inactive';
            return {
                ...state,
                [activeKey]: {
                    ...state[activeKey],
                    data: action.payload.newRulesData,
                },
            };
        }
        case 'update_rule_active_status':
            return updateRuleActiveStatus(
                state,
                action.payload.ruleId,
                action.payload.newActiveStatus,
                action.payload.account,
            );
        default:
            console.error(`rulesDataReducer does not have action ${action}`);
            return state;
    }
}

function updateRuleActiveStatus(
    currentRulesDataAndState: RulesDataAndState,
    ruleId: number,
    newActiveStatus: boolean,
    account: AccountInfo | null,
): RulesDataAndState {
    const originKey = newActiveStatus ? 'inactive' : 'active';
    const originData = currentRulesDataAndState[originKey].data;
    const destinationKey = newActiveStatus ? 'active' : 'inactive';
    const destinationData = currentRulesDataAndState[destinationKey].data;

    const index = originData.findIndex(({ id }) => id === ruleId);
    if (index > -1) {
        const rule = originData[index];
        originData.splice(index, 1);
        destinationData.push({
            ...rule,
            active: newActiveStatus,
            lastModifiedAt: new Date(),
            lastModifiedBy: account ? account.username : '',
        });

        return {
            [originKey]: {
                ...currentRulesDataAndState[originKey],
                data: originData,
            },
            [destinationKey]: {
                ...currentRulesDataAndState[destinationKey],
                data: destinationData,
            },
        } as RulesDataAndState;
    } else {
        return currentRulesDataAndState;
    }
}

type RuleDataContext = {
    rulesDataAndState: RulesDataAndState;
    reloadRulesData: () => void;
    updateRuleActiveStatus: (ruleId: number, newActiveStatus: boolean) => void;
};

export const RuleDataContext = createContext<RuleDataContext | undefined>(undefined);

export function useRules() {
    const ruleContext = useContext(RuleDataContext);

    if (ruleContext === undefined) {
        const message = 'Error! useRules hook used outside of the context provider.';
        throw new Error(message);
    }

    return ruleContext;
}

export function RuleDataProvider({ children }: { children?: ReactNode }) {
    const apiClient = useAxios();
    const { addToast } = useToast();
    const account = useAccount();

    const [rulesDataAndState, dispatchRulesDataAndState] = useReducer(rulesDataAndStateReducer, {
        active: { data: [], loading: false, reload: true },
        inactive: { data: [], loading: false, reload: true },
    });

    useEffect(() => {
        const fetchRules = async (active: boolean) => {
            dispatchRulesDataAndState({
                type: 'set_loading_state',
                payload: { isActive: active, newLoadingState: true },
            });
            try {
                const res = await apiClient.get(ENDPOINT_URLS.GET_RULES, {
                    params: { active: active },
                });

                if (res.status !== 200) {
                    throw new Error(`Error with status ${res.status}`);
                }

                const parsedData = parseRulesData(res.data);
                dispatchRulesDataAndState({
                    type: 'set_rules_data',
                    payload: { isActive: active, newRulesData: parsedData },
                });
                dispatchRulesDataAndState({
                    type: 'set_reload_state',
                    payload: { isActive: active, newReloadState: false },
                });
            } catch (error) {
                addDataDogError(error as Error);
                addToast({
                    type: 'danger',
                    content: `Fetching Rules API call failed: ${(error as Error).message}.`,
                });
                dispatchRulesDataAndState({
                    type: 'set_rules_data',
                    payload: { isActive: active, newRulesData: [] },
                });
            } finally {
                dispatchRulesDataAndState({
                    type: 'set_loading_state',
                    payload: { isActive: active, newLoadingState: false },
                });
            }
        };

        if (rulesDataAndState.active.reload) {
            fetchRules(true);
        } else if (rulesDataAndState.inactive.reload) {
            fetchRules(false);
        }
    }, [addToast, apiClient, rulesDataAndState.active.reload, rulesDataAndState.inactive.reload]);

    useEffect(() => {
        async function reactToUpdateFromRuleAPI(event: MessageEvent) {
            if (event.data.meta === 'workbox-broadcast-update') {
                const { cacheName, updatedURL } = event.data.payload;
                // TODO: replace magic strings with constants
                if (cacheName === 'apiCache' && updatedURL.includes('rule_list')) {
                    console.info('Received background update from API: ', updatedURL);
                    const cache = await caches.open(cacheName);
                    const updatedResponse = await cache.match(updatedURL);
                    const rawData = await updatedResponse?.json();
                    if (updatedURL.includes('rule_list/?active=true')) {
                        dispatchRulesDataAndState({
                            type: 'set_rules_data',
                            payload: { isActive: true, newRulesData: parseRulesData(rawData) },
                        });
                    } else if (updatedURL.includes('rule_list/?active=false')) {
                        dispatchRulesDataAndState({
                            type: 'set_rules_data',
                            payload: { isActive: false, newRulesData: parseRulesData(rawData) },
                        });
                    }
                }
            }
        }
        navigator.serviceWorker.addEventListener('message', reactToUpdateFromRuleAPI);
        return () => navigator.serviceWorker.removeEventListener('message', reactToUpdateFromRuleAPI);
    }, []);

    return (
        <RuleDataContext.Provider
            value={{
                rulesDataAndState: rulesDataAndState,
                reloadRulesData: () => {
                    dispatchRulesDataAndState({
                        type: 'set_reload_state',
                        payload: { isActive: true, newReloadState: true },
                    });
                    dispatchRulesDataAndState({
                        type: 'set_reload_state',
                        payload: { isActive: false, newReloadState: true },
                    });
                },
                updateRuleActiveStatus: (ruleId: number, newActiveStatus: boolean) =>
                    dispatchRulesDataAndState({
                        type: 'update_rule_active_status',
                        payload: { ruleId: ruleId, newActiveStatus: newActiveStatus, account: account },
                    }),
            }}
        >
            {children}
        </RuleDataContext.Provider>
    );
}

export const parseRulesData = (data: Record<string, any>[]): RuleData[] => {
    const parsedData = data.map((elem: object) =>
        _.mapKeys(elem, (_v, k) => {
            return _.camelCase(k);
        }),
    );

    return parsedData.map((item: any) => {
        item.cityPairs = item.cityPairs.map((cityPair: CityPairArray) => cityPairArrayToString(cityPair));
        item.createdAt = new Date(item.createdAt);
        item.lastModifiedAt = new Date(item.lastModifiedAt);
        item.includedDates = item.includedDates.map((dateRange: [string, string]) => {
            return dateRange.map((date) => {
                const d = date.split('-').map(Number);
                const included = new Date(Date.UTC(d[0], d[1] - 1, d[2], 0, 0, 0));
                return included;
            });
        });
        item.excludedDates = item.excludedDates.map((dateRange: [string, string]) => {
            return dateRange.map((date) => {
                const d = date.split('-').map(Number);
                const excluded = new Date(Date.UTC(d[0], d[1] - 1, d[2], 0, 0, 0));
                return excluded;
            });
        });

        return item as RuleData;
    });
};
